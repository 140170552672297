export const environment = {
  production: false,
  envName: "Stage",
  version: "1.0",
  withSSO: true,
  logging: true,
  popupTimer: 300, //5 minute before
  splunk: {
    URL: "https://agero-stg.apigee.net/ageroappsapi/services/collector/event",
    ApiKey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    splunkEnvAuthKey: "C370136B-5B32-543F-AC7C-4991211A8E11",
  },
  clientCode: {
    Agero: "AGR",
  },
  API: {
    getSettingsApi:
      "https://agero-stg.apigee.net/ageroappsapi/v1/GetConfigurationSettings",
    apikey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    DPLExistingCasesApi: "https://agero-stg.apigee.net/ageroappsapi/v1/GetCallbackSearchCaseData",
    getProgramList: "https://agero-stg.apigee.net/ageroappsapi/v1/GetProgramList",
  },
  adminGroup: "Agero_VIMS_AgeroAdmin",

  ageroAuthConfig: {
    clientId: "5k956q566rs71llvhr0obijq3s",
    useMultiTenantApi: true,
    appWebDomain: "login.nonprod.oauth.agero.com",
    redirectUriSignIn: "callback.html",
    redirectUriSignOut: "logout.html",
    apiFullUrl: "https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1",
    refreshTimeoutOffset: 10 * 60,
    // How many seconds of inactivity before the user is signed out.  If not included, defaults to 30 minutes
    activityTimeout: 30 * 60,
  },
  Star2URL: "https://d2u6kgt8j2hiiu.cloudfront.net",
  DispatchLiteURL: "https://dfv5idxh4uikl.cloudfront.net",
  RSADashboardURL: "https://d2izlsqy5psser.cloudfront.net",
  LocateMeURL: "https://d1q98630xeossm.cloudfront.net/locate.html",
  CTI:"https://d1ph11r127vgxk.cloudfront.net/",
  ConfigurationsURL: "https://d1xz76r3u5e3q3.cloudfront.net",
  IVRConfig: "https://d2zp9cb4xq4rdb.cloudfront.net",
  SocketConnectionURL : "wss://7advil46f3.execute-api.us-east-1.amazonaws.com/stage?header="
};
